<template>
  <CRow id="increaseInventory">
    <div class="body-h-fix w-100" v-if="loading">
      <vue-element-loading
        :active="loading"
        :text="loadingText"
        spinner="bar-fade-scale"
        color="var(--secondary)"
      />
    </div>
    <CCol md="12" id="increaseInventoryFarmList">
      <CRow class="TransactionListBox gap-20">
        <CCol col="12" class="inner-content" v-for="(item, farmName) of buyServiceDetailList" :key="farmName">
          <h6>
            زمین {{ farmName }}
          </h6>
          <hr class="mx-4 mt-2"/>
          <CRow class="buyFarmBoxItem pb-2 gap-10 px-4">
            <CCol col="auto" class="position-relative vertical-g-5 main-card buyFarmBoxItemContent" v-for="(date,index) of item" :key="index">
              <span class="dot-normal dot-normal-1"></span>
              <span class="dot-normal dot-normal-2"></span>
              <div>
                <label class="year">{{ date.year }}</label>
                <label class="month">{{ date.month.label }}</label>
              </div>
                <CButton
                  size="sm"
                  color="primary"
                  v-c-tooltip="{
                content: 'جزئیات زمین',
                placement: 'top',
              }"
                  @click="getFarmInfoWithId(date.farmId)"
                >
                  جزئیات
                </CButton>
            </CCol>
          </CRow>

        </CCol>
      </CRow>
    </CCol>
    <VueModal
      v-model="modelShowState"
      :title="modalTitle"
      wrapper-class="animate__animated animate__faster"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
      bg-class="animate__animated"
      style="width: 800px !important; max-width: 800px !important"
    >
      <component
        v-if="subForm != null"
        v-bind:is="subForm"
        :farmInfoItem="farmInfoModel"
      />
    </VueModal>
  </CRow>
</template>

<script>
import buyServiceInfo from "./buyServiceInfo.vue";
import farmInfo from "./farmInfo.vue";
import months from "../../../../store/data/month.json";
import {mapActions} from "vuex";
import polyGonStructure from "../../../../store/data/polygonFeature.json";
import * as turf from "@turf/turf";

export default {
  components: {
    buyServiceInfo,
    farmInfo,
  },
  data() {
    return {
      loading: false,
      loadingText: "",
      modelShowState: false,
      modalTitle: "",
      subForm: null,
      allmonths: months,
      tabPaneActiveKey: 1,
      buyServiceDetailList: [],
      farmInfoModel: {
        title: "",
        productType: "",
        productName: "",
        cultivationsTypeTitle: "",
        cultivationsDate: "",
        stateName: "",
        cityName: "",
        district: "",
        village: "",
        farmArea: null
      },
    };
  },
  computed: {},
  methods: {
    ...mapActions("financialUser", [
      "ReportGetAllFarmAndDateTimeCurrentUser",
      "ReportGetBuyServiceInfoWithDetailId",
    ]),
    ...mapActions("farm", ["Get"]),

    async getAllBuyServiceDetail() {
      this.loading = true;
      this.loadingText = "دریافت لیست تمام فاکتورهای خرید شده...";
      let result = await this.ReportGetAllFarmAndDateTimeCurrentUser();
      this.loading = false;
      if (result) {
        if (result.succeeded) {
          let groupedByFarm = result.data.reduce(
            (result, item) => {
              const {farmName} = item;
              if (!result[farmName]) {
                result[farmName] = [];
              }
              result[farmName].push({
                id: item.id,
                buyServiceId: item.buyServiceId,
                farmId: item.farmId,
                farmName: item.farmName,
                year: item.year,
                month: this.allmonths.find((x) => x.value == item.month),
              });
              return result;
            },
            {}
          );
          for (const farmName in groupedByFarm) {
            groupedByFarm[farmName].sort((a, b) => {
              // Sort by year first
              if (a.year !== b.year) {
                return b.year - a.year;
              }
              // If years are the same, sort by month
              return b.month - a.month;
            });
          }
          this.buyServiceDetailList = groupedByFarm;
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `${result.message} <br /><br />`,
            type: "error",
          });
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `خطا رخ داده است <br /><br />`,
          type: "error",
        });
      }
    },
    async getFarmInfoWithId(farmId) {
      this.loading = true;
      this.loadingText = "دریافت اطلاعات زمین ...";
      const result = await this.Get({id: farmId});
      this.loading = false;
      if (result.id > 0) {
        this.farmInfoModel.title = result.title;
        this.farmInfoModel.productType = result.productType;
        this.farmInfoModel.productName = result.productName;
        this.farmInfoModel.cultivationsTypeTitle = result.cultivationsTypeTitle;
        this.farmInfoModel.cultivationsDate = result.cultivationsDate;
        this.farmInfoModel.stateName = result.stateName;
        this.farmInfoModel.cityName = result.cityName;
        this.farmInfoModel.district = result.district;
        this.farmInfoModel.village = result.village;
        let polygon = result.area.substring(
          result.area.indexOf("("),
          result.area.indexOf(")")
        );
        polygon = polygon.replaceAll("(", "");
        polygon = polygon.replaceAll(")", "");
        polygon = polygon.split(", ");
        polygon = polygon.map((item) => item.split(" "));
        polyGonStructure.features[0].geometry.coordinates = [polygon];
        let farmArea = turf.area(polyGonStructure.features[0].geometry);
        this.farmInfoModel.farmArea = Intl.NumberFormat("fa").format(
          (farmArea / 10000).toFixed(2)
        );
        this.subForm = "farmInfo";
        this.modelShowState = true;
        this.modalTitle = ` مشخصات زمین ${result.title} `;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message} <br /><br />`,
          type: "error",
        });
      }
    },
    async getBuyServiceWithDetailId(buyserviceId) {
    },
  },
  mounted() {
    this.getAllBuyServiceDetail();
  },
  created() {
  },
};
</script>

<style scoped>
.filterSelection {
  text-align: center;
  cursor: pointer;
}

.filterSelectionIcon {
  width: 20px;
  height: 30px;
  padding: 5px;
  font-size: 1rem;
}

#accountInfo {
  width: 100%;
}

#increaseInventory {
  width: 100%;
  height: 100%;
  margin-top: 6px;
}

#increaseInventoryFarmList {
  width: 100%;
  height: calc(100% - 8px);
  overflow-y: auto;
  overflow-x: hidden;
}


.transactionFilterBtn {
  border: none;
  margin-right: 7px;
  font-size: 13px;
  padding: 0 6px 0 7px;
}

.TransactionListItem {
  width: 99%;
  margin-top: 7px;
  box-shadow: -4px 3px 4px 0 #34343494;
  height: fit-content;
}

.TransactionType {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  font-size: 19px;
  padding: 9px 12px 0 0;
  margin: 0 -3% 0 0;
}

.buyFarmBoxItem {
  row-gap: 20px;
}


.farmArea {
  border-radius: 100%;
  width: 20px;
  height: 20px;
  padding: 4px;
  margin: -18px -5px 0 0;
}

.year {
  margin: 0;
}

.month {
  height: 4.1vh;
  border-radius: 0 0 4px 4px;
  padding-top: 9px;
  margin: 0;
}

.itemActionBtn {
  border-radius: 100%;
}

.dot-normal{
  top: -9px;
  box-shadow: 0 0 4px 0 #ccc;
}
.dot-normal::before{
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 3px;
  height: 3px;
  background: #aaa;
  border-radius: 50%;
}

.dot-normal-1{
  left: 25%;
  transform: translateX(-25%);
}
.dot-normal-2{
  left: 75%;
  transform: translateX(-75%);
}

.buyFarmBoxItemContent{
  overflow: visible;
}
</style>
